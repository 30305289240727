<template>
  <div>
    <p class="title">账单下载</p>
    <div style="margin:10px;textAlign:left">
      <el-radio-group v-model="book_type" name='yewu_type' @change="chan">
        <el-radio-button label="1">日账单</el-radio-button>
        <el-radio-button label="2">月账单</el-radio-button>
      </el-radio-group>
      <label class="type_lab" for="select">时间</label>
      <el-select v-model="year" clearable placeholder="请选择年份" style="width:150px" name="select" @change="type">
        <el-option v-for="item in txoptions" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-select v-model="mon" v-if="year && book_type == 1" clearable placeholder="请选择月份" @change="getdays"
        style="width:150px;margin-left:10px" name="select">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </div>
    <div v-if="book_type == 1">
      <el-table max-height="720" :data="tableData" border style="width: 99%;marginLeft:10px">
        <el-table-column prop="day" label="日期">
        </el-table-column>
        <el-table-column prop="income_money" label="收入（元）">
        </el-table-column>
        <el-table-column prop="income" label="收入笔数">
        </el-table-column>
        <el-table-column prop="outlay_money" label="支出（元）">
        </el-table-column>
        <el-table-column prop="outlay" label="支出笔数">
        </el-table-column>
        <el-table-column prop="opening_balance" label="期初余额">
        </el-table-column>
        <el-table-column prop="ending_balance" label="期末余额">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="lookdeta(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- <el-pagination
         style="margin-top:5px;textAlign:center"
        background
        prev-text='上一页'
        next-text='下一页'
        layout="total , prev, pager, next, jumper"
        @current-change="current_change"
        :total="total"
        :page-size="pagesize">   
    </el-pagination> -->
    </div>
    <div v-if="book_type == 2">
      <el-table max-height="720" :data="monData" border style="width: 99%;marginLeft:10px">
        <el-table-column prop="month" label="月份">
        </el-table-column>
        <el-table-column prop="income_money" label="收入（元）">
        </el-table-column>
        <el-table-column prop="income" label="收入笔数">
        </el-table-column>
        <el-table-column prop="outlay_money" label="支出（元）">
        </el-table-column>
        <el-table-column prop="outlay" label="支出笔数">
        </el-table-column>
        <el-table-column prop="opening_balance" label="期初余额">
        </el-table-column>
        <el-table-column prop="ending_balance" label="期末余额">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="lookmon(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['chanlist'],
  data() {
    return {
      monData: [],
      currentPage: 1,
      pagesize: 10,
      total: 0,
      tableData: [],
      options: [],
      mon: "",
      txoptions: [],
      year: "",
      book_type: "1"
    }
  },
  mounted() {
    this.chanlist('3-2', '3')
    this.getyears()
    this.getdays()
  },
  methods: {
    lookmon(row) {
      this.$router.push({ name: "Monbill", params: { mon: row.month, year: this.year } })
    },
    lookdeta(row) {
      var mons;
      var days;
      if (this.mon < 10) {
        mons = '0' + this.mon
      } else {
        mons = this.mon
      }
      if (row.day < 10) {
        days = '0' + row.day
      } else {
        days = row.day
      }
      var time = this.year + '-' + mons + '-' + days
      this.$router.push({ name: "Daybill", params: { time } })
    },
    type() {
      if (this.book_type == 2) {
        this.getmons()
      }
    },
    chan() {
      if (this.book_type == 1) {
        this.getdays()
      } else {
        let date = new Date()
        this.year = date.getFullYear()
        this.getmons()
      }
    },
    current_change(page) {
      this.currentPage = page;
      this.getdays()
    },
    getyears() {
      let date = new Date()
      this.year = date.getFullYear()
      this.mon = date.getMonth() + 1
      for (let i = 0; i < 1; i++) {
        this.txoptions.push({
          value: date.getFullYear() - i,
          label: date.getFullYear() - i
        })
      }
      for (let i = 0; i < this.mon; i++) {
        this.options.push({
          value: i + 1,
          label: i + 1
        })
      }
    },
    getmons() {
      this.$axios.post("/supplier/billing/month", { year: this.year }).then((res) => {
        if (res.data.code == 20000) {
          this.monData = res.data.data.data
        } else {
          this.$message({
            type: "warning",
            message: res.data.message
          })
        }
      })
    },
    getdays() {
      this.$axios.post('/supplier/billing/day', { year: this.year, month: this.mon }).then((res) => {
        if (res.data.code == 20000) {
          this.tableData = res.data.data.data
        } else {
          this.$message({
            type: "warning",
            message: res.data.message
          })
        }
      })
    }
  },
}
</script>

<style lang="less" scoped>
.title {
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
  border-bottom: 1px solid gray;
  text-align: left;
  font-size: 20px;
}

.type_lab {
  height: 34px;
  width: 90px;
  display: inline-block;
  line-height: 34px;
  text-align: right;
  font-size: 14px;
  padding: 0 10px
}
</style>